import { DateInput } from "@naf/teamscheme";
import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useMemo,
} from "react";
import { Schema } from "#vehicle-contract/common/lib/common";
import type { DateString } from "#vehicle-contract/common/lib/model/common/DateString";
import { SchemaType } from "#vehicle-contract/common/lib/model/contract/SchemaType";
import FormGroup from "../../forms/FormGroup";
import { LabeledInputComponent } from "../../forms/LabeledInputComponent";
import { LabeledSelect } from "../../forms/LabeledSelect";
import { LabelHint } from "../form/fields/LabelHint";
import { SCHEMA_TYPE_LABELS } from "./SchemaTypeLabels";

export type CustomFieldValue = string | number | DateString | undefined;

export interface CustomVehicleDataFields {
  schemaType: SchemaType;
  chassisNumber?: string;
  manufactureName?: string;
  modelName?: string;
  registrationYear?: number;
  firstTimeRegistered?: DateString;
}

export interface CustomVehicleDataProps {
  fields: CustomVehicleDataFields;
  setFields: Dispatch<SetStateAction<CustomVehicleDataFields>>;
}

interface CustomVehicleDataFieldDefinition {
  name: keyof CustomVehicleDataFields;
  type: "text" | "int" | "date";
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}

export default function CustomVehicleData({
  fields,
  setFields,
}: CustomVehicleDataProps) {
  const schema = useMemo(
    () => new Schema(fields.schemaType),
    [fields.schemaType],
  );

  const onChangeField = (field: string) => (value: CustomFieldValue) =>
    setFields((x) => ({ ...x, [field]: value }));

  const onChangeFieldEvent =
    (field: string) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      onChangeField(field)(value);

  const definitions: CustomVehicleDataFieldDefinition[] = [
    { name: "chassisNumber", type: "text" },
    { name: "manufactureName", type: "text", required: true },
    { name: "modelName", type: "text", required: true },
    { name: "registrationYear", type: "int", minLength: 4, maxLength: 4 },
    { name: "firstTimeRegistered", type: "date" },
  ];

  return (
    <div>
      <FormGroup>
        <LabeledSelect
          label="Velg kjøretøy"
          id="schemaType"
          value={fields.schemaType.toString()}
          onChange={(value) =>
            onChangeField("schemaType")(Number.parseInt(value ?? "0"))
          }
          options={[
            SchemaType.Car,
            SchemaType.Motorbike,
            SchemaType.Camper,
            SchemaType.CampingTrailer,
            SchemaType.Trailer,
            SchemaType.Default,
          ].map((type) => ({
            label: SCHEMA_TYPE_LABELS[type],
            value: type.toString(),
          }))}
        />
      </FormGroup>
      {definitions.map(({ name, type, required, ...props }) => {
        const label = schema.getFieldLabel("vehicleData", name);
        const placeholder =
          schema.getFieldPlaceholder("vehicleData", name) ?? "";

        function getInputComponent() {
          switch (type) {
            case "int": {
              const intValue = fields[name];
              return (
                <input
                  {...props}
                  required={required}
                  name={name}
                  type="tel"
                  value={intValue !== undefined ? intValue.toString() : ""}
                  onChange={({ target: { value } }) =>
                    onChangeField(name)(
                      value === "" ? undefined : Number.parseFloat(value),
                    )
                  }
                  placeholder={placeholder}
                />
              );
            }
            case "date": {
              const onChange = onChangeField(name);
              const value = fields[name] as DateString | undefined;
              return (
                <DateInput
                  {...props}
                  required={required}
                  value={value === undefined ? null : new Date(value)}
                  onChange={(value) =>
                    onChange(value === null ? undefined : value.toISOString())
                  }
                />
              );
            }
            default:
              return (
                <input
                  {...props}
                  name={name}
                  required={required}
                  type={type}
                  value={fields[name] || ""}
                  onChange={onChangeFieldEvent(name)}
                  placeholder={placeholder}
                />
              );
          }
        }

        return (
          <FormGroup key={name}>
            <LabeledInputComponent
              id={`vehicleData.${name}`}
              label={
                <>
                  {label} {required ? <LabelHint> (påkrevd)</LabelHint> : null}
                </>
              }
            >
              {getInputComponent()}
            </LabeledInputComponent>
          </FormGroup>
        );
      })}
    </div>
  );
}
