import { onDesktop } from "@naf/teamscheme";
import type { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import AboutButtonLink from "../about/AboutButtonLink";
import { AppPages } from "../routing/AppPages";
import { normalizeComponentTree } from "../shared/utils/normalizeComponentTree";
import { ComparisonTable } from "../contract/contract-type/contract-upgrade/ComparisonTable";
import { StaticTypeSelector } from "../contract/contract-type/contract-upgrade/StaticTypeSelector";

const UpgradeSectionTitle = styled.h2`
  margin: 0;

  ${({ theme }) => theme.mobileFontStyle.headers.header1};
  margin-bottom: ${({ theme }) => theme.spacing.space24};

  ${({ theme }) => css`
    ${onDesktop`
      ${theme.fontStyle.headers.header1};
      text-align: center;
      max-width: ${8 * 96}px;
      margin: 0 auto ${theme.spacing.space48};
    `}
  `}
`;

const UpgradeSectionIngress = styled.div`
  margin: 0 auto ${({ theme }) => theme.spacing.space24};

  ${onDesktop`
    text-align: center;
  `}
`;

const UpgradeSectionTitleText = <>Kom i gang med NAF Kjøpekontrakt</>;
export const UPGRADE_SECTION_TITLE_ID = normalizeComponentTree(
  UpgradeSectionTitleText,
).replaceAll(/\s/g, "-");
const UpgradeSection = styled(function (
  props: Omit<HTMLAttributes<HTMLDivElement>, "children">,
) {
  return (
    <div {...props}>
      <UpgradeSectionTitle id={UPGRADE_SECTION_TITLE_ID}>
        {UpgradeSectionTitleText}
      </UpgradeSectionTitle>
      <UpgradeSectionIngress>
        Alle NAF-medlemmer får automatisk Premium gjennom medlemskapet.
      </UpgradeSectionIngress>
      <article>
        <ComparisonTable />
        <StaticTypeSelector />
        <AboutButtonLink variant="primary" page={AppPages.AboutUpgrade}>
          Mer om oppgradering
        </AboutButtonLink>
      </article>
    </div>
  );
})`
  position: relative;
  width: 100%;
  max-width: 1080px;

  ${({ theme }) => css`
    margin: ${theme.spacing.space24} auto ${theme.spacing.space64};
    ${onDesktop`
      margin: 0 auto;
    `}
  `}
`;

export default UpgradeSection;
